
import { mapGetters } from 'vuex';
import ToolsMixin from '@/mixins/ToolsMixin';
import CommonMixin from '@/mixins/CommonMixin';
import { FTPConfig } from '@/models/FTPConfig';
import { handleErrorUI, handleSuccessUI } from '@/util/error';

export default {
  mixins: [ToolsMixin, CommonMixin],
  data() {
    return {
      connectionStatus: '',
      checking: false,
      loading: false,
      config: new FTPConfig()
    };
  },
  computed: {
    ...mapGetters('tenant', ['hasResetAccount'])
  },
  async created() {
    const config = await this.$repo.extension.getFTPConfiguration();
    this.config = config;
    if (this.config != null) {
      this.connectionStatus = 'Test Connection Failed';
    }
  },
  methods: {
    async checkConnection() {
      this.loading = true;
      this.checking = true;
      try {
        const status = await this.$repo.extension.checkFTPConnection(
          this.config
        );
        if (status) {
          this.connectionStatus = 'Test Connection Success';
        }
      } catch (e) {
        handleErrorUI(e);
      } finally {
        this.loading = false;
      }
    },
    async saveSettings() {
      this.loading = true;
      try {
        await this.$repo.extension.upsertFTPConfiguration(this.config);
        handleSuccessUI('FTP configuration saved successfully');
      } catch (e) {
        handleErrorUI(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
